import {IUrlShortener} from "@/interfaces/IUrl-shortener";
import axios from "axios";
import {UrlService} from "@/services/url.service";
import {HttpError} from "@/modals/http-error";
import {StatDayToDayMapper} from "@/mappers/stat-day-to-day.mapper";
import {UrlListMapper} from "@/mappers/url-list.mapper";
import {AppActionsEnum} from "@/store/app/types/app-actions.enum";
import {authorizationHeaderWithToken} from "carps-helper-functions";
import store from "@/store";
import {UserGettersEnum} from "@/store/user/types/user-getters.enum";

export const AppActions = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async [AppActionsEnum.CREATE_VANITY_URL]({commit}: any, formData: IUrlShortener): Promise<any> {
        return axios({
            method: "post",
            url: UrlService.URL_SHORTENER,
       headers: authorizationHeaderWithToken(store.getters[UserGettersEnum.GET_API_TOKEN]),
            data: formData
        })
            .then((response: any) => {
                return response.data
            })
            .catch((error: any) => {
                console.error(error);
                if (error.response) {
                    return Promise.reject(new HttpError(error.response));
                }
                return Promise.reject(new Error(error));
            });
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async [AppActionsEnum.GET_URL_CLICK_STATS]({commit}: any, vanity: string): Promise<any> {
        return axios({
            method: "post",
            headers: authorizationHeaderWithToken(store.getters[UserGettersEnum.GET_API_TOKEN]),
            url: UrlService.URL_CLICK_STATS,
            data: {
                vanity: vanity.split('/')[1]
            }
        })
            .then((response: any) => {
                return response.data
            })
            .catch((error: any) => {
                console.error(error);
                if (error.response) {
                    return Promise.reject(new HttpError(error.response));
                }
                return Promise.reject(new Error(error));
            });
    },

    async [AppActionsEnum.URL_CLICK_STATS_BY_DAY]({commit}: any, vanity: string): Promise<any> {
        const vanityLast = vanity.split('/')
        return axios({
            method: "post",
       headers: authorizationHeaderWithToken(store.getters[UserGettersEnum.GET_API_TOKEN]),
            url: UrlService.URL_CLICK_STATS_BY_DAY,
            data: {
                vanity: vanityLast[vanityLast.length - 1]
            }
        })
            .then((response: any) => {
                commit('storeClickStats', StatDayToDayMapper.mapStats(response.data))
                return response.data
            })
            .catch((error: any) => {
                console.error(error);
                if (error.response) {
                    return Promise.reject(new HttpError(error.response));
                }
                return Promise.reject(new Error(error));
            });
    },

    async [AppActionsEnum.GET_ALL_URLS]({commit}: any): Promise<any> {
        return axios({
            method: "get",
       headers: authorizationHeaderWithToken(store.getters[UserGettersEnum.GET_API_TOKEN]),
            url: UrlService.URL_LIST,
        })
            .then((response: any) => {
                commit('storeTotalLinks', response.data.urlList.length)
                const mapped = UrlListMapper.mapUrlList(response.data)
                commit('storeMappedLinks', mapped)
                return mapped
            })
            .catch((error: any) => {
                console.error(error);
                if (error.response) {
                    return Promise.reject(new HttpError(error.response));
                }
                return Promise.reject(new Error(error));
            });
    }
}
