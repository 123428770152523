import {AppGettersEnum} from "@/store/app/types/app-getters.enum";

export const AppGetters = {
    [AppGettersEnum.GET_APP_SETTINGS]: (state: any) => state.appSettings,
    [AppGettersEnum.GET_TOTAL_LINKS]: (state: any) => state.urlLinks.totalUrls,
    [AppGettersEnum.GET_LINK_STATS]: (state: any) => state.linkStats,
    [AppGettersEnum.GET_ALL_LINKS]: (state: any) => state.urlLinks,
    [AppGettersEnum.GET_POPULAR]: (state: any) => state.urlLinks.popular,
    [AppGettersEnum.GET_UNUSED_LINKS]: (state: any) => state.urlLinks.totalUnused,
    [AppGettersEnum.GET_NEWEST_URL]: (state: any) => state.urlLinks.newestUrl,
    [AppGettersEnum.GET_APP_LOADING_STATE]: (state: any) => state.appState.isLoading,
    [AppGettersEnum.GET_NEW_NOTIFICATIONS]: (state: any) => state.notificationData,
}
