
import {Component, Vue, Watch} from 'vue-property-decorator';
import AddNew from "@/components/AddNew.vue";
import MainHeader from "@/components/core/MainHeader.vue";
import {Getter} from "vuex-class";
import MainNav from "@/components/core/MainNav.vue";
import {PollingService} from "@/services/polling.service";
import {AppGettersEnum} from "@/store/app/types/app-getters.enum";

const polling = new PollingService();

@Component({
  components: {MainNav, MainHeader, AddNew},
})
export default class DashboardHome extends Vue {
  @Getter(AppGettersEnum.GET_APP_SETTINGS) settings!: any

  @Watch('settings.polling')
  onPropertyChanged() {
    polling.stopPolling()
    polling.startPolling()
  }

  public mounted(): void {
    polling.startPolling();
  }
}
