import {isRunningInDevMode} from "carps-helper-functions";

export class UrlService {
    static BASE_URI: string | undefined = (() => {
        if (isRunningInDevMode(process.env.VUE_APP_IS_LOCAL)) {
            return '/api/'
        }
        return `${process.env.VUE_APP_API_SERVER_URI}/api/`
    })()
    static URL_SHORTENER = `${UrlService.BASE_URI}URLShortener`
    static URL_CLICK_STATS = `${UrlService.BASE_URI}UrlClickStats`
    static URL_CLICK_STATS_BY_DAY = `${UrlService.BASE_URI}UrlClickStatsByDay`
    static URL_LIST = `${UrlService.BASE_URI}urlList`
}
