import {UserGettersEnum} from "@/store/user/types/user-getters.enum";

export const UserGetters = {
    [UserGettersEnum.GET_USER_PROFILE]: (state: any) => state.user.profile,
    [UserGettersEnum.GET_USER_ROLES]: (state: any) => state.user.roles,
    [UserGettersEnum.GET_API_TOKEN]: (state: any) => state.apiToken,
    [UserGettersEnum.GET_USERS_ME_TOKEN]: (state: any) => state.meToken,
    [UserGettersEnum.GET_ADMIN_STATUS]: (state: any) => state.isAdmin,
    [UserGettersEnum.GET_USER_STATUS]: (state: any) => state.isUser,
    [UserGettersEnum.GET_USER_TOKEN_DATA]: (state: any) => state.userTokenData,
}
