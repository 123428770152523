
import {Component, Prop, Vue} from 'vue-property-decorator';
import {IGeneratedUrl} from "@/interfaces/IGeneratedUrl";

@Component({
  components: {},
})
export default class GeneratedUrl extends Vue {
  @Prop({required: true}) readonly generatedData!: IGeneratedUrl | null
}
