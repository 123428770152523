
import {Component, Vue} from 'vue-property-decorator';
import {Getter} from "vuex-class";
import {UserGettersEnum} from "@/store/user/types/user-getters.enum";
import {IUserProfile} from "@/interfaces/IUserProfile";

@Component({
  components: {},
})
export default class Profile extends Vue {
  @Getter(UserGettersEnum.GET_USER_PROFILE) profile!: IUserProfile
}
