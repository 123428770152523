import store from "@/store";
import {Constants} from "@/data/constants";
import {UserActionsEnum} from "@/store/user/types/user-actions.enum";

export class MappingHelperService {
    public static async getPhoto(userId: string): Promise<string> {
        try {
            const result = await store.dispatch(UserActionsEnum.GET_PHOTO, userId)
            const blob = new Blob(
                [result],
                {type: Constants.IMAGE_TYPE_JPEG}
            )
            return window.URL.createObjectURL(blob);
        } catch (e) {
            console.error(e);
            return require('./../assets/user.png')
        }
    }
}
