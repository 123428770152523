import axios from "axios";
import {HelperService} from "@/services/helper.service";
import {UserActionsEnum} from "@/store/user/types/user-actions.enum";
import {UserMutationsEnum} from "@/store/user/types/user-mutations.enum";
import store from "@/store";
import {UserGettersEnum} from "@/store/user/types/user-getters.enum";
import {Constants} from "@/data/constants";
import {AppMutationsEnum} from "@/store/app/types/app-mutations.enum";
import {msalInstance} from "carps-vue-msal";
import {authorizationHeaderWithToken} from "carps-helper-functions";
import {NotificationMessage} from "@/modals/notification-message";


export const UserActions = {

    async [UserActionsEnum.LOGOUT](): Promise<void> {
        await msalInstance?.logoutRedirect()
    },
    async [UserActionsEnum.TOKEN_REFRESH_TIMER]({commit, getters, dispatch}: any) {
        const updateToken = (async () => {
            const existingTokenResponse = getters[UserGettersEnum.GET_USER_TOKEN_DATA];
            let newTokenResponse: any;
            if (existingTokenResponse) {
                newTokenResponse = await msalInstance?.acquireTokenSilent({
                    account: existingTokenResponse.account,
                    scopes: [process.env.VUE_APP_SCOPE_BE_API_AUTH_ENDPOINT]
                });
            }
            commit(UserMutationsEnum.STORE_API_TOKEN, newTokenResponse.accessToken)
            return newTokenResponse
        })

        setInterval(() => {
            console.info('Updating Token');
            updateToken()
        }, (5 * 60000)) //15 mins;

        return Promise.resolve(updateToken())
    },

    async [UserActionsEnum.LOGIN]({commit, getters, dispatch}: any) {
        try {
            const existingTokenResponse = getters[UserGettersEnum.GET_API_TOKEN];
            let newTokenResponse: any;

            if (existingTokenResponse) {
                newTokenResponse = await msalInstance?.acquireTokenSilent({
                    account: existingTokenResponse.account,
                    scopes: msalInstance?.extendedConfiguration.auth.scopes,
                });
            } else {
                newTokenResponse = await msalInstance?.handleRedirectPromise();
            }
            if (!newTokenResponse) {
                await msalInstance?.loginRedirect(msalInstance?.loginRequest);
                return false;
            } else if (newTokenResponse) {
                const roles = newTokenResponse.idTokenClaims.roles

                HelperService.STORE_AND_PROCESS_USER_ACCESS(roles, newTokenResponse);
                const profile = await store.dispatch(UserActionsEnum.CALL_MS_GRAPH, {
                    token: newTokenResponse.accessToken,
                    endpoint: `https://graph.microsoft.com/v1.0/me`
                })

                const apiEndpointToken: any = await msalInstance?.acquireTokenSilent({
                    account: newTokenResponse.account,
                    scopes: [process.env.VUE_APP_SCOPE_BE_API_AUTH_ENDPOINT],
                });
                store.commit(UserMutationsEnum.STORE_API_TOKEN, apiEndpointToken.accessToken)
                store.commit(UserMutationsEnum.STORE_USER_TOKEN_DATA, apiEndpointToken)

                store.commit(AppMutationsEnum.STORE_NOTIFICATION_DATA,
                    new NotificationMessage.Builder()
                        .setTitle('Welcome')
                        .setMessage('Your login was successful.')
                        .setTypeAsInfo()
                        .setTimeout(2)
                )

                await UserActions[UserActionsEnum.TOKEN_REFRESH_TIMER]({commit, getters, dispatch});

                return Promise.resolve(profile)
            }
            return true;
        } catch (error) {
            console.error(error);
            return Promise.reject(error)
        }
    },


    async [UserActionsEnum.GET_PHOTO]({commit}: any, userId: string): Promise<any> {
        return axios({
            method: "get",
            responseType: 'arraybuffer',
            url: `https://graph.microsoft.com/v1.0/users/${userId}/photos/96x96/$value`,
            headers: authorizationHeaderWithToken(store.getters[UserGettersEnum.GET_USERS_ME_TOKEN])
        })
            .then((response: any) => {
                return response.data;
            })
            .catch((error: any) => {
                if (error.response.status !== Constants.HTTP_STATUS_CODE_NOT_FOUND) {
                    console.error(error);
                }
                return Promise.reject(new Error(error));
            });
    },

    async [UserActionsEnum.CALL_MS_GRAPH]({commit}: any, obj: any): Promise<any> {
        return axios({
            method: "get",
            url: obj.endpoint,
            headers: authorizationHeaderWithToken(obj.token)
        })
            .then((response: any) => {
                commit(UserMutationsEnum.STORE_USER_PROFILE, response.data)
                return response;
            })
            .catch((error: any) => {
                console.error(error);
                return Promise.reject(new Error(error));
            });
    },
}
