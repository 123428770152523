export class LocalStorageService {
    private storageKey = 'url-shorty-app';

    public saveAppSettings(value: any): void {
        if (value !== null) {
            localStorage.setItem(this.storageKey, JSON.stringify(value));
        }
    }

    public getAppSettings(): any {
        const data = localStorage.getItem(this.storageKey);
        return data !== null ? JSON.parse(data) : null;
    }
}
