import {ProfileMapper} from "@/mappers/profile.mapper";
import {UserMutationsEnum} from "@/store/user/types/user-mutations.enum";

export const UserMutations = {
    async [UserMutationsEnum.STORE_USER_PROFILE](state: any, payload: any) {
        state.user.profile = await ProfileMapper.mapProfile(payload)
    },
    [UserMutationsEnum.STORE_USER_ROLES](state: any, payload: any) {
        state.user.roles = payload
    },
    [UserMutationsEnum.STORE_API_TOKEN](state: any, payload: string) {
        state.apiToken = payload
    },
    [UserMutationsEnum.STORE_USER_ME_TOKEN](state: any, payload: string) {
        state.meToken = payload
    },
    [UserMutationsEnum.STORE_ADMIN_STATUS](state: any, payload: boolean) {
        state.isAdmin = payload
    },
    [UserMutationsEnum.STORE_USER_STATUS](state: any, payload: boolean) {
        state.isUser = payload
    },
    [UserMutationsEnum.STORE_USER_TOKEN_DATA](state: any, payload: any) {
        state.userTokenData = payload
    },
}
