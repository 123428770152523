export enum AppGettersEnum {
    GET_APP_SETTINGS = 'getAppSettings',
    GET_TOTAL_LINKS = 'getTotalLinks',
    GET_LINK_STATS = 'getLinkStats',
    GET_ALL_LINKS = 'getAllLinks',
    GET_POPULAR = 'getPopular',
    GET_UNUSED_LINKS = 'getUnusedLinks',
    GET_NEWEST_URL = 'getNewestUrl',
    GET_APP_LOADING_STATE = 'getAppLoadingState',
    GET_NEW_NOTIFICATIONS = 'getNewNotifications',
}
