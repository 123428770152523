
import {Component, Vue} from 'vue-property-decorator';
import {NotificationRoot, ShowNotification} from "carps-ui";
import {AppGettersEnum} from "@/store/app/types/app-getters.enum";
import {Getter} from "vuex-class";

@Component({
  components: {
    NotificationRoot,
    ShowNotification
  },
})
export default class App extends Vue {
  @Getter(AppGettersEnum.GET_NEW_NOTIFICATIONS) notificationsData: any
}
