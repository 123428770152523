import {NotificationsEnum} from "@/enum/notifications.enum";
import {IMutationNotification} from "@/interfaces/IMutationNotification";

export class NotificationMessage implements IMutationNotification {
    public static Builder = class {
        private isMultiLine = false
        private message = ''
        private timeout = 0
        private title = ''
        private type: NotificationsEnum = NotificationsEnum.INFO

        setMessage(message: string) {
            this.message = message
            return this
        }

        setTitle(title: string) {
            this.title = title
            return this
        }

        setTypeAsError() {
            this.type = NotificationsEnum.ERROR
            return this
        }

        setTypeAsSuccess() {
            this.type = NotificationsEnum.SUCCESS
            return this
        }

        setTypeAsInfo() {
            this.type = NotificationsEnum.INFO
            return this
        }

        setTypeAsWarning() {
            this.type = NotificationsEnum.WARNING
            return this
        }

        setType(type: NotificationsEnum) {
            this.type = type
            return this
        }

        setIsMultiLine(isMultiLine: boolean) {
            this.isMultiLine = isMultiLine
            return this
        }

        setTimeout(timeout: number) {
            this.timeout = timeout
            return this
        }

        build() {
            return new NotificationMessage(
                this.title,
                this.message,
                this.type,
                this.isMultiLine,
                this.timeout
            )
        }
    }
    public isMultiLine = false
    public message = ''
    public timeout = 0
    public title = ''
    public type: NotificationsEnum = NotificationsEnum.INFO

    constructor(title: string, message: string, type: NotificationsEnum, isMultiline = false, timeout = 4) {
        this.title = title
        this.message = message
        this.type = type
        this.isMultiLine = isMultiline
        this.timeout = timeout
    }
}
