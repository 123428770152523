import {IDayStats} from "@/interfaces/IDay-stats";
import {IUrlStat} from "@/interfaces/IUrl-stat";
import {checkDateIsToday, checkDateWasYesterday} from "carps-helper-functions";

export class StatDayToDayMapper {
    public static mapStats(response: any): IDayStats {
        const temp: IDayStats = {
            url: response.url,
            today: null,
            yesterday: null,
            allDays: response.items.map((item: any): IUrlStat => {
                    return {
                        dateClicked: new Date(item.dateClicked),
                        count: item.count,
                        url: response.url
                    }
                }
            )
        }
        if (temp.allDays.length > 0) {
            temp.allDays.forEach((day: IUrlStat) => {
                if (checkDateIsToday(day.dateClicked)) {
                    temp.today = day
                }
                if (checkDateWasYesterday(day.dateClicked)) {
                    temp.yesterday = day
                }
            })
        }
        return temp
    }
}
