
import {Component, Vue} from 'vue-property-decorator';
import TopLinks from "@/components/TopLinks.vue";
import ClickDetail from "@/components/ClickDetail.vue";

@Component({
  components: {ClickDetail, TopLinks},
})
export default class AllUrls extends Vue {
}
