import {IHttpError} from "@/interfaces/IHttpError";

export class HttpError extends Error {
    private readonly httpError: IHttpError;

    constructor(response: any) {
        super(`${response.data.Message || response.statusText}`);
        this.name = 'HttpError';
        this.httpError = {
            message: response.data.Message || response.statusText,
            httpCode: response.status,
            messageDetail: response.data.MessageDetail,
            httpHeaders: response.headers
        }
    }

    public fullError(): IHttpError {
        return this.httpError;
    }

}
