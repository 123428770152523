
import {Component, Vue} from 'vue-property-decorator';
import {Action, Getter, Mutation} from "vuex-class";
import {LocalStorageService} from "@/services/local-storage.service";
import {UserGettersEnum} from "@/store/user/types/user-getters.enum";
import {IUserProfile} from "@/interfaces/IUserProfile";
import {UserActionsEnum} from "@/store/user/types/user-actions.enum";
import {AppGetters} from "@/store/app/app-getters";
import {AppGettersEnum} from "@/store/app/types/app-getters.enum";
import {AppMutationsEnum} from "@/store/app/types/app-mutations.enum";

@Component({
  components: {},
})
export default class MainHeader extends Vue {
  @Getter(UserGettersEnum.GET_USER_PROFILE) user!: IUserProfile
  @Action(UserActionsEnum.LOGOUT) mainLogout!: () => Promise<void>
  @Getter(AppGettersEnum.GET_APP_SETTINGS) settings!: any
  @Mutation(AppMutationsEnum.STORE_IS_DARK) setTheme!: (isDark: boolean) => void

  public toggleTheme(): void {
    this.setTheme(!this.settings.isDark)
    const appSettings = new LocalStorageService();
    appSettings.saveAppSettings(this.settings);
  }

  public logout(): void {
    this.mainLogout()
    console.info('Logged Out');
  }
}
