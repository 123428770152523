import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/styles/index.css'
import msalPlugin from "carps-vue-msal";
import {Constants} from "@/data/constants";
import {isRunningInDevMode} from "carps-helper-functions";

const isRunningLocally = isRunningInDevMode(process.env.VUE_APP_IS_LOCAL);

console.info('Environment: ' + process.env.VUE_APP_ENVIRONMENT)

const msalConfig = {
    auth: {
        tenant: process.env.VUE_APP_MSAL_LOGIN_TENANT,
        clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
        authority: process.env.VUE_APP_MSAL_LOGIN_AUTHORITY + process.env.VUE_APP_MSAL_LOGIN_TENANT,
        redirectUri: isRunningLocally ? Constants.LOCAL_BASE_URL : process.env.VUE_APP_BASE_URL,
        scopes: Constants.DEFAULT_SCOPES
    },
    mode: Constants.MSAL_MODE,
}
Vue.use(msalPlugin, msalConfig);

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
