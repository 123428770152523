
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Action, Getter} from "vuex-class";
import {IUrlLists} from "@/interfaces/IUrl-lists";
import {AppGettersEnum} from "@/store/app/types/app-getters.enum";
import {AppActionsEnum} from "@/store/app/types/app-actions.enum";

@Component({
  components: {},
})
export default class TopLinks extends Vue {
  @Prop({default: false}) readonly showAll!: boolean
  @Action(AppActionsEnum.URL_CLICK_STATS_BY_DAY) details!: any
  @Getter(AppGettersEnum.GET_ALL_LINKS) urlLinks!: IUrlLists

  public async showDetail(link: string) {
    const a = await this.details(link)
  }

}
