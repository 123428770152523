
import {Component, Vue} from 'vue-property-decorator';
import StatCard from "@/components/StatCard.vue";
import TopLinks from "@/components/TopLinks.vue";
import {Getter} from "vuex-class";
import ClickDetail from "@/components/ClickDetail.vue";
import Top10Chart from "@/components/Top10Chart.vue";
import {AppGetters} from "@/store/app/app-getters";
import {AppGettersEnum} from "@/store/app/types/app-getters.enum";

@Component({
  components: {Top10Chart, ClickDetail, TopLinks, StatCard},
})
export default class DashStats extends Vue {
  @Getter(AppGettersEnum.GET_TOTAL_LINKS) links!: number
  @Getter(AppGettersEnum.GET_POPULAR) mostClicked!: string
  @Getter(AppGettersEnum.GET_UNUSED_LINKS) unused!: number
  @Getter(AppGettersEnum.GET_NEWEST_URL) newest!: string
}
