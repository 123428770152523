
import {Component, Vue} from 'vue-property-decorator';
import {Getter} from "vuex-class";
import {LoadingSpinner} from "carps-ui";
import {AppGettersEnum} from "@/store/app/types/app-getters.enum";

@Component({
  components: {LoadingSpinner},
})
export default class StartPage extends Vue {
  @Getter(AppGettersEnum.GET_APP_LOADING_STATE) isLoading!: boolean
}
