
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Chart from 'chart.js'

@Component({
  components: {},
})
export default class DoughnutChart extends Vue {
  @Prop({default: []}) readonly labels!: Array<string>
  @Prop({default: []}) readonly colors!: Array<string>
  @Prop({default: []}) readonly data!: Array<number>
  @Prop({
    default: () => new Object()
  }) readonly options!: any

  @Prop({
    default: () => {
      return Chart.defaults.doughnut
    }
  })

  public mounted(): void {
    this.createChart({
      datasets: [
        {
          data: this.data,
          backgroundColor: this.colors
        }
      ],
      labels: this.labels,
    })
  }

  public createChart(chartData: any): void {
    const canvas = this.$refs.doughnut as HTMLCanvasElement
    const options = {
      type: 'doughnut',
      data: chartData,
      options: {...this.options}
    }
    new Chart(canvas, options)
  }
}
