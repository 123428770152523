import {Module} from "vuex";
import {UserGetters} from "@/store/user/user-getters";
import {UserMutations} from "@/store/user/user-mutations";
import {UserActions} from "@/store/user/user-actions";

const userModule: Module<any, any> = {
    state: {
        user: {
            name: null,
            profile: null,
            roles: []
        },
        meToken: null,
        apiToken: null,
        userId: null,
        token: null,
        isAdmin: false,
        isUser: false,
        userTokenData: {},
    },
    mutations: {...UserMutations},
    getters: {...UserGetters},
    actions: {...UserActions}
}

export default userModule;
