import {Module} from "vuex";
import {IUrlLists} from "@/interfaces/IUrl-lists";
import {AppGetters} from "@/store/app/app-getters";
import {AppMutations} from "@/store/app/app-mutations";
import {AppActions} from "@/store/app/app-actions";

const appModule: Module<any, any> = {
    state: {
        appState: {
            isLoading: false
        },
        notificationData: null,
        appSettings: {
            isDark: false,
            polling: '30'
        },
        totalLinks: 0,
        linkStats: null,
        urlLinks: {
            newestUrl: '',
            totalUrls: 0,
            totalUnused: 0,
            topTen: [],
            allUrls: [],
            popular: '',
        } as IUrlLists
    },
    mutations: {...AppMutations},
    getters: {...AppGetters},
    actions:{...AppActions}
}

export default appModule;
