
import {Component, Vue} from 'vue-property-decorator';
import {Action} from "vuex-class";
import {IUrlShortener} from "@/interfaces/IUrl-shortener";
import {AppActionsEnum} from "@/store/app/types/app-actions.enum";

@Component({
  components: {},
})
export default class AddNew extends Vue {
  @Action(AppActionsEnum.CREATE_VANITY_URL) add!: (formData: any) => Promise<any>

  public formData: any = {
    url: {
      value: '',
      error: false,
      required: true
    },
    title: {
      value: '',
      error: false,
      required: false
    },
    vanity: {
      value: '',
      error: false,
      required: false
    },
  };

  private validateAndProcess(): boolean | IUrlShortener {
    let valid = true;
    let data: any = {};
    for (const property in this.formData) {
      if (this.formData[property].value === '' && this.formData[property].required) {
        valid = false;
        this.formData[property].error = true
        return valid;
      } else {
        if (this.formData[property].value !== '')
          data[property] = this.formData[property].value
      }
    }
    return data
  }

  public async create(): Promise<any> {
    const isValid = this.validateAndProcess();
    if (isValid) {
      const x = await this.add(isValid)
      if (x) {
        this.$emit('save', x)
      }
    }
  }
}
