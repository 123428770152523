import {AppMutationsEnum} from "@/store/app/types/app-mutations.enum";
import {IUrlLists} from "@/interfaces/IUrl-lists";

export const AppMutations = {
    [AppMutationsEnum.STORE_IS_DARK](state: any, payload: boolean): void {
        state.appSettings.isDark = payload
    },
    [AppMutationsEnum.STORE_POLLING](state: any, payload: number): void {
        state.appSettings.polling = payload
    },
    [AppMutationsEnum.STORE_TOTAL_LINKS](state: any, payload: number): void {
        state.totalLinks = payload
    },
    [AppMutationsEnum.STORE_CLICK_STATS](state: any, payload: any): void {
        state.linkStats = payload
    },
    [AppMutationsEnum.STORE_MAPPED_LINKS](state: any, payload: IUrlLists): void {
        state.urlLinks = payload
    },
    [AppMutationsEnum.STORE_APP_LOADING_STATE](state: any, payload: boolean): void {
        state.appState.isLoading = payload
    },
    [AppMutationsEnum.STORE_NOTIFICATION_DATA](state: any, payload: any) {
        state.notificationData = payload;
    },
}
