import store from "@/store";
import {UserMutationsEnum} from "@/store/user/types/user-mutations.enum";
import {Constants} from "@/data/constants";

export class HelperService {
    public static STORE_AND_PROCESS_USER_ACCESS(roles: Array<string>, result: any): void {
        store.commit(UserMutationsEnum.STORE_USER_ROLES, roles)
        store.commit(UserMutationsEnum.STORE_ADMIN_STATUS, HelperService.IS_ADMIN_STATUS(roles))
        store.commit(UserMutationsEnum.STORE_USER_STATUS, HelperService.IS_IT_DEPARTMENT(roles))
        store.commit(UserMutationsEnum.STORE_USER_ME_TOKEN, result.accessToken)
    }

    private static IS_ADMIN_STATUS(roles: Array<string>): boolean {
        return roles.includes(process.env.VUE_APP_AD_ROLE_ADMIN)
    }

    private static IS_IT_DEPARTMENT(roles: Array<string>): boolean {
        return roles.includes(process.env.VUE_APP_AD_ROLE_USER)
    }

    static getJustShortUrl(str: string): string {
        const temp: string[] = str.split('/')
        return temp[temp.length - 1]
    }

    static colourList(coloursReq: number): Array<string> {
        const blues = [
            '#4a7ff6',
            '#154eda',
            '#3150a4',
            '#839def',
            '#0240f3']
        const colors: any = []
        let color: string = blues[0]
        while (colors.length < coloursReq) {
            color = blues[Math.floor(Math.random() * blues.length)]
            if(!colors.includes(color)) {
                colors.push(color)
            }
        }
        return colors
    }
}
