import {IUserProfile} from "@/interfaces/IUserProfile";
import {MappingHelperService} from "@/services/mapping-helper.service";

export class ProfileMapper {
    public static async mapProfile(response: any): Promise<IUserProfile> {
        return {
            businessPhones: response.businessPhones.map((phone: string) => phone),
            displayName: response.displayName,
            id: response.id,
            givenName: response.givenName,
            mail: response.mail,
            jobTitle: response.jobTitle,
            mobilePhone: response.mobilePhone,
            officeLocation: response.officeLocation,
            preferredLanguage: response.preferredLanguage,
            surname: response.surname,
            userPrincipalName: response.userPrincipalName,
            photo: await MappingHelperService.getPhoto(response.mail),
            username: response.userPrincipalName.split('@')[0] || ''
        }
    }
}
