
import {Component, Vue, Watch} from 'vue-property-decorator';
import {Getter} from "vuex-class";
import {IUrlLists} from "@/interfaces/IUrl-lists";
import {HelperService} from "@/services/helper.service";
import DoughnutChart from "@/components/graphs/DoughnutChart.vue";
import {AppGettersEnum} from "@/store/app/types/app-getters.enum";

@Component({
  components: {DoughnutChart},
})

export default class Top10Chart extends Vue {
  @Getter(AppGettersEnum.GET_ALL_LINKS) urlLinks!: IUrlLists
  @Getter(AppGettersEnum.GET_APP_SETTINGS) settings!: any

  public data: Array<string> = [];
  public labels: Array<string> = [];
  public colors: Array<string> = [];
  public options: any = {
    responsive: true,
    maintainAspectRatio: true,
    title: {
      display: true,
      text: 'Total Clicks',
      fontColor: '#4a7ff6',
    },
    color: 'white',
    legend: {
      position: 'top',
      labels: {
        fontColor: '#4a7ff6'
      }
    }
  }

  public created(): void {
    this.urlLinks.topTen.map((item: any) => {
          this.labels.push(HelperService.getJustShortUrl(item.shortUrl))
          this.data.push(`${item.clicks}`)
        }
    )
    this.colors = HelperService.colourList(this.urlLinks.topTen.length)
  }
}
