
import {Component, Vue} from 'vue-property-decorator';
import AddNew from "@/components/AddNew.vue";
import GeneratedUrl from "@/components/GeneratedUrl.vue";

@Component({
  components: {GeneratedUrl, AddNew},
})
export default class NewUrl extends Vue {
  public generatedResponse = null
}
