
import {Component, Prop, Vue} from 'vue-property-decorator';
import ClickItem from "@/components/ClickItem.vue";
import {Getter} from "vuex-class";
import {IDayStats} from "@/interfaces/IDay-stats";
import {AppGettersEnum} from "@/store/app/types/app-getters.enum";
import {longDateFormatter} from "carps-helper-functions";

@Component({
  components: {ClickItem},
  filters: {
    longDateFormatter,
  }
})
export default class ClickDetail extends Vue {
  @Prop({default: false}) readonly showAll!: boolean
  @Getter(AppGettersEnum.GET_LINK_STATS) stats!: IDayStats
}
