export class Constants {
    public static MSAL_MODE = 'redirect'

    public static HTTP_STATUS_CODE_NOT_FOUND = 404
    public static HTTP_STATUS_CODE_UNAUTHORISED = 401

    public static LOCAL_BASE_URL = 'http://localhost:8082/'
    public static DEFAULT_SCOPES = ['user.read','profile','openid','email']
    public static IMAGE_TYPE_JPEG = 'image/jpeg'
}
