import store from "@/store";
import {AppActionsEnum} from "@/store/app/types/app-actions.enum";
import {AppGettersEnum} from "@/store/app/types/app-getters.enum";

export class PollingService {
    private pollingInterval: any;

    public startPolling(): void {
        this.pollingInterval = setInterval(
            this.updateUrlList,
            store.getters[AppGettersEnum.GET_APP_SETTINGS].polling * 1000
        )
    }

   public stopPolling(): void {
        clearInterval(this.pollingInterval)
    }

   private async updateUrlList(): Promise<void> {
        await store.dispatch(AppActionsEnum.GET_ALL_URLS)
        console.info('Auto Updated - URL List')
    }
}
