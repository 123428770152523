import Vue from 'vue'
import Vuex from 'vuex'
import appModule from "@/store/app/app";
import userModule from "@/store/user/user";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        appModule,
        userModule
    }
})

