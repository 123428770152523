import {IUrlLists} from "@/interfaces/IUrl-lists";
import {IUrlList} from "@/interfaces/IUrl-list";
import {HelperService} from "@/services/helper.service";

export class UrlListMapper {
    public static mapUrlList(response: any): IUrlLists {
        let unusedCount = 0;
        const temp: IUrlLists = {
            newestUrl: '',
            totalUnused: 0,
            totalUrls: response.urlList.length,
            popular: '',
            topTen: [],
            allUrls: response.urlList
                .sort((a: any, b: any): any => b.clicks - a.clicks)
                .map((item: any): IUrlList => {
                    if(item.clicks === 0) {
                        unusedCount++
                    }
                    return {
                        url: item.url,
                        shortUrl: item.shortUrl,
                        clicks: item.clicks,
                        activeUrl: item.activeUrl,
                        isArchived: item.isArchived,
                        schedules: item.schedules,
                        title: item.title,
                        vanity: item.vanity,
                        algorithm: item.algorithm,
                        authority: item.authority,
                        clicksByCountry: item.clicksByCountry,
                        version: item.version
                    }
                })
        }
        const allLinks = [...temp.allUrls]
        allLinks.sort((a: any, b: any): any => b.timestamp - a.timestamp)
        temp.newestUrl = HelperService.getJustShortUrl(allLinks[0].shortUrl)
        temp.totalUnused = unusedCount
        temp.topTen = [...temp.allUrls.filter((item: any, index: number) => index < 5)]
        const split = temp.topTen[0].shortUrl.split('/');
        temp.popular = temp.topTen[0].shortUrl.split('/')[split.length - 1]
        return temp
    }
}
