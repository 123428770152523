
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class StatCard extends Vue {
  @Prop({required: true}) readonly number!: string|number
  @Prop({required: true}) readonly statName!: string
  @Prop({required: true}) readonly iconNo!: number
}
