
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ClickItem extends Vue {
  @Prop({required: true}) readonly url!: string
  @Prop({required: true}) readonly longUrl!: string
  @Prop({required: true}) readonly clicks!: string
}
