import Vue from 'vue'
import VueRouter, {NavigationGuardNext, Route, RouteConfig} from 'vue-router'
import DashboardHome from "@/views/DashboardHome.vue";
import DashStats from "@/views/DashStats.vue";
import NewUrl from "@/views/NewUrl.vue";
import EditUrl from "@/views/EditUrl.vue";
import Settings from "@/views/Settings.vue";
import Profile from "@/views/Profile.vue";
import Analytics from "@/views/Analytics.vue";
import store from '@/store';
import AllUrls from "@/views/AllUrls.vue";
import {LocalStorageService} from "@/services/local-storage.service";
import StartPage from "@/views/StartPage.vue";
import {RouteEnums} from "@/enum/route.enums";
import {UserGettersEnum} from "@/store/user/types/user-getters.enum";
import {UserActionsEnum} from "@/store/user/types/user-actions.enum";
import {AppActionsEnum} from "@/store/app/types/app-actions.enum";
import {AppMutationsEnum} from "@/store/app/types/app-mutations.enum";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        component: StartPage,
        beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext) => {
            try {
                const profileData = await store.dispatch(UserActionsEnum.LOGIN)
                if (profileData) {
                    if (store.getters[UserGettersEnum.GET_USER_STATUS] ||
                    store.getters[UserGettersEnum.GET_ADMIN_STATUS]) {
                    next()
                    } else {
                    next({name: RouteEnums.UNAUTHORISED})
                    }
                } else {
                    next({name: RouteEnums.ERROR})
                }

            } catch (e: any) {
                next({name: RouteEnums.ERROR})
                console.error(e);
            }
        },
        children: [
            {
                path: '',
                redirect: 'dashboard'
            },
            {
                path: 'dashboard',
                component: DashboardHome,
                beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext) => {

                    const settingsService = new LocalStorageService()
                    const appSettings = settingsService.getAppSettings();

                    if (appSettings) {
                        store.commit(AppMutationsEnum.STORE_IS_DARK, appSettings.isDark)
                        if (appSettings.polling) {
                            store.commit(AppMutationsEnum.STORE_POLLING, appSettings.polling)
                        }
                    }

                    store.dispatch(AppActionsEnum.GET_ALL_URLS);
                    store.commit(AppMutationsEnum.STORE_APP_LOADING_STATE, false)
                    next();

                },
                children: [
                    {
                        path: '',
                        name: 'dash-stats',
                        component: DashStats,
                    },
                    {
                        path: 'show-all-urls',
                        name: 'show-all-urls',
                        component: AllUrls,
                    },
                    {
                        path: 'new-url',
                        name: 'new-url',
                        component: NewUrl,
                    },
                    {
                        path: 'edit-url',
                        name: 'edit-url',
                        component: EditUrl,
                    },
                    {
                        path: 'settings',
                        name: 'settings',
                        component: Settings,
                    },
                    {
                        path: 'profile',
                        name: 'profile',
                        component: Profile,
                    },
                    {
                        path: 'analytics',
                        name: 'analytics',
                        component: Analytics,
                    },
                ]
            }
        ]
    },
    {
        path: '/error',
        name: RouteEnums.ERROR,
        component: () => import('../views/AppError.vue'),
        beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext) => {
            store.commit(AppMutationsEnum.STORE_APP_LOADING_STATE, false)
            next()
        }
    },
    {
        path: '/unauthorised',
        name: RouteEnums.UNAUTHORISED,
        component: () => import('../views/NotAuthorised.vue'),
        beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext) => {
            store.commit(AppMutationsEnum.STORE_APP_LOADING_STATE, false)
            next()
        }
    },
    {
        path: '*',
        name: RouteEnums.NOT_FOUND,
        component: () => import('../views/NotFound.vue'),
        beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext) => {
            store.commit(AppMutationsEnum.STORE_APP_LOADING_STATE, false)
            next()
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
