
import {Component, Vue} from 'vue-property-decorator';
import {InputField} from "carps-ui";
import {Getter, Mutation} from "vuex-class";
import {LocalStorageService} from "@/services/local-storage.service";
import {AppGettersEnum} from "@/store/app/types/app-getters.enum";
import {AppMutationsEnum} from "@/store/app/types/app-mutations.enum";
import {IMutationNotification} from "@/interfaces/IMutationNotification";
import {NotificationMessage} from "@/modals/notification-message";

@Component({
  components: {
    InputField
  },
})
export default class Settings extends Vue {
  @Getter(AppGettersEnum.GET_APP_SETTINGS) settings!: any
  @Mutation(AppMutationsEnum.STORE_POLLING) setTime!: (time: string) => void
  @Mutation(AppMutationsEnum.STORE_NOTIFICATION_DATA) sendNotification!: (notificationData: IMutationNotification) => void

  public timeIsValid = true
  public time = '20'

  public mounted(): void {
    this.time = this.settings.polling;
  }

  public save(): void {
    this.setTime(this.time);
    const appSettings = new LocalStorageService();
    appSettings.saveAppSettings(this.settings);
    this.sendNotification(
        new NotificationMessage.Builder()
            .setTitle('Settings Updated')
            .setTimeout(3)
            .setMessage('Your settings have been successfully updated')
            .setTypeAsSuccess()
            .build()
    )
  }
}
